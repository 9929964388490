import { useMediaQuery, useTheme } from "@mui/material";

export function useScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Mobile is < 768px
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg")); // Desktop is >= 1200px
  // Tablet (maybe small desktop) is >=768px and <1200px

  if (isMobile) return "mobile";
  if (isDesktop) return "desktop";
  return "tablet";
}
